<template>
  <div
    class="relative w-full select-none lg:aspect-[123/55]"
    data-el="ui-slider-hero"
  >
    <Swiper
      v-if="props.slidesCount > 1"
      :slides-per-view="1"
      :loop="true"
      :modules="[Autoplay, Navigation]"
      :speed="800"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }"
      :resize-observer="false"
      class="size-full"
      @swiper="swiperInit"
      @slide-change="slideChange"
      @autoplay-time-left="updateProgressBar"
    >
      <template
        v-for="(slide, index) in $slots.default?.()?.[0]?.children"
        :key="`hero-slide-${index}`"
      >
        <SwiperSlide>
          <component :is="slide" />
        </SwiperSlide>
      </template>
    </Swiper>

    <!-- single slide -->
    <slot v-else />

    <!-- progress bar and thumbnails nav -->
    <div
      v-if="props.slidesCount > 1"
      class="relative z-20 bg-primary xl:bg-transparent"
      aria-hidden="true"
    >
      <nav
        class="container flex h-14 w-full items-center justify-between gap-5 lg:gap-6 xl:absolute xl:bottom-0.5 xl:left-1/2 xl:h-24 xl:-translate-x-1/2 xl:justify-start"
      >
        <!-- counterprogress bar and navigation -->
        <UiButton
          ref="prev"
          variant="icon"
          icon-left="arrow-left"
          class="swiper-button-hero-prev order-2 lg:order-1"
          @click="goToSlide('prev')"
        />

        <div class="order-1 mr-auto flex h-full min-w-24 items-center lg:order-2 lg:mr-0">
          <!-- counter -->
          <span
            class="shrink-0 text-base font-bold text-white drop-shadow-md xl:leading-5"
            :class="{ invisible: !slider }"
          >
            <span class="text-white">{{ slideNumber(currentSlideNumber) }}</span>
            <span style="color: #164b70">
              / {{ slideNumber(props.slidesCount) }}
            </span>
          </span>
          <!-- progress bar -->
          <ClientOnly>
            <div
              class="ml-3.5 h-0.5 w-14 grow overflow-hidden rounded-full drop-shadow-md lg:w-16 lg:min-w-0 lg:grow-0 xl:ml-2.5"
              :class="{ invisible: !slider }"
            >
              <div class="size-full bg-white/50">
                <div
                  :style="`width: ${autoplayProgress}%`"
                  class="size-full rounded-full bg-white transition-width"
                />
              </div>
            </div>
          </ClientOnly>
        </div>

        <UiButton
          ref="next"
          variant="icon"
          icon-right="arrow"
          class="swiper-button-hero-next order-3"
          @click="goToSlide('next')"
        />
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper/modules'
import type SwiperClass from 'swiper'
import UiButton from '../UiButton/UiButton.vue'
import 'swiper/css'

const props = defineProps<{
  slidesCount: number
}>()

const slider = ref<SwiperClass | undefined>(undefined)
const currentSlideNumber = ref<number>(1)
const autoplayProgress = ref<number>(0)
const next = ref(null)
const prev = ref(null)

function swiperInit(swiper: SwiperClass): void {
  slider.value = swiper
}

function slideChange(swiper: SwiperClass): void {
  currentSlideNumber.value = swiper.realIndex + 1
}

function slideNumber(number: number): string {
  return String(number).padStart(2, '0')
}

function goToSlide(direction: 'prev' | 'next'): void {
  if (!slider.value || slider.value?.destroyed) {
    return
  }

  switch (direction) {
    case 'prev': {
      slider.value.slidePrev()
      break
    }
    case 'next': {
      slider.value.slideNext()
      break
    }
  }
}

function updateProgressBar(
  _swiper: typeof Swiper,
  _time: number,
  progres: number,
): void {
  requestAnimationFrame(() => {
    autoplayProgress.value = Number(100 - progres * 100)
  })
}

onBeforeUnmount(() => {
  if (slider.value) {
    slider.value.destroy(true, true)
  }
})
</script>
